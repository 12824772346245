export default function (subTitleSource = "", action) {
  if (
    action.type === "updateSubTitleSource" &&
    action.subTitleSource !== null
  ) {
    let newSubTitleSource = action.subTitleSource;
    return newSubTitleSource;
  } else {
    return subTitleSource;
  }
}
