import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Navigation from "./navigation.component";

import RadioScreen from "./radioScreen.component";
import Footer from "./footer.component";

import { Helmet } from "react-helmet";
import MixSection from "./mixSection.component";

function HomeNique(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Helmet>
        <title>Nique - la radio</title>
        <link rel="icon" href="/NIQUE_LOGO.ico" />
        <meta
          name="Nique - la radio"
          content="Nique - la radio, le Global Dancefloor"
        />
        {/* Open Graph / Facebook  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nique.radio/" />
        <meta property="og:title" content="Nique - la radio" />
        <meta
          property="og:description"
          content="Nique – La radio, la webradio global dancefloor et label de podcasts (Banana Kush, Faya, Allez le sang...)"
        ></meta>
        <meta
          property="og:image"
          content="https://nique.radio/nique-website.png"
        />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nique.radio/" />
        <meta property="twitter:title" content="Nique - la radio" />
        <meta
          property="twitter:description"
          content="Nique – La radio, la webradio global dancefloor et label de podcasts (Banana Kush, Faya, Allez le sang...)"
        />
        <meta
          property="twitter:image"
          content="https://nique.radio/nique-website.png"
        />

        <link rel="apple-touch-icon" href="/nique192.png" />
      </Helmet>
      <Navigation />
      <RadioScreen audioMedia={props.audioMedia} />
      <MixSection />
      <Footer />
    </Container>
  );
}

export default HomeNique;
