import React from "react";
import Row from "react-bootstrap/Row";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <div style={{ backgroundColor: "#FFBCD2" }}>
      <Row style={screenContainer} className="text-center">
        <p style={textStyle}>
          © Nique - La radio / Tous droits réservés
          <br />
          <Link to="/mentions-legales">Mentions légales</Link>
        </p>
      </Row>
    </div>
  );
}

export default Footer;

const screenContainer = {
  backgroundColor: "#FFBCD2",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  padding: "1rem 2rem",
  maxWidth: 1000,
  margin: "auto",
};

const textStyle = {
  fontSize: "0.75rem",
  lineHeigth: "0.75rem",
};
