import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

//navigation
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//redux
import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import source from "./reducers/source.reducer";
import titleSource from "./reducers/titleSource.reducer";
import subTitleSource from "./reducers/subTitleSource.reducer";
import duration from "./reducers/duration.reducer";
import coverSource from "./reducers/coverSource.reducer";
import playerStatus from "./reducers/playerStatus.reducer";
import selectedPodcast from "./reducers/selectedPodcast.reducer";

//navigation
import Player from "./components/player.component";
import HomeNique from "./components/homeNique.component";
import MentionsLegalesScreen from "./components/mentionsLegalesScreen.component";
import Navigation from "./components/navigation.component";

const store = createStore(
  combineReducers({
    source,
    titleSource,
    subTitleSource,
    duration,
    coverSource,
    playerStatus,
    selectedPodcast,
  })
);

function App() {
  let audioMedia = useRef();

  function HomeNiqueWithPlayer() {
    return <HomeNique audioMedia={audioMedia} />;
  }

  function MentionsLegaleswithPlayer() {
    return <MentionsLegalesScreen audioMedia={audioMedia} />;
  }

  return (
    <Provider store={store}>
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={HomeNiqueWithPlayer} />
          <Route
            path="/mentions-legales"
            component={MentionsLegaleswithPlayer}
          />
        </Switch>
        <Player audioMedia={audioMedia} />
      </Router>
    </Provider>
  );
}

export default App;
