import React from "react";
import { Button } from "react-bootstrap";

export default function SoniqueLinkButton() {
  return (
    <div
      className="soniquelinkButton"
      style={{
        backgroundColor: "#FFBCD2",
      }}
    >
      <a
        href="https://sonique.studio/"
        target="_balnk"
        rel="noopener noreferrer"
        className="sonique-link"
      >
        <Button style={customBtn} type="submit" className="sonique-link">
          NOS PODCASTS SONIQUE
        </Button>
      </a>
    </div>
  );
}

const customBtn = {
  color: "#000000",
  backgroundColor: "#FFBCD2",
  border: "3px solid black",
  borderRadius: 0,
  fontSize: "1.5rem",
  fontWeight: 700,
};
