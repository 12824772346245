import React from "react";
import { Row } from "react-bootstrap";

function MixSection() {
  return (
    <div
      style={{
        backgroundColor: "#FFBCD2",
        padding: "24px",
        textAlign: "center",
      }}
    >
      <Row
        style={{ backgroundColor: "#FFBCD2", maxWidth: 1000, margin: "auto" }}
        className="rowRature"
      >
        <img
          src="/images/RATURE_LARGE.svg"
          style={{ margin: "2em auto", width: "100%" }}
          alt="Nique la Radio - rature"
        ></img>
      </Row>
      <h2 style={titleStyle}>DJ SHOWS</h2>
      <iframe
        title="mix-embeds"
        width="100%"
        height="300"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1180067896&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
      <div
        style={{
          fontSize: "10px",
          color: "#cccccc",
          lineBreak: "anywhere",
          wordBreak: "normal",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontFamily:
            "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
          fontXeight: 100,
        }}
      >
        <a
          href="https://soundcloud.com/niquelaradio"
          title="Nique – La radio"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Nique – La radio
        </a>{" "}
        ·{" "}
        <a
          href="https://soundcloud.com/niquelaradio/sets/nique-le-dancefloor"
          title="Nique - Le Dancefloor"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Nique - Le Dancefloor
        </a>
      </div>
    </div>
  );
}

export default MixSection;

const titleStyle = {
  fontSize: "2.25rem",
  fontWeight: 700,
  padding: "1.25rem 0",
};
