import React from "react";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";

function RadioScreen(props) {
  let radioSource = "https://www.radioking.com/play/nique-la-radio";

  function playRadio() {
    props.audioMedia.current.load();
    props.audioMedia.current.play();
    props.updateSource(radioSource);
    props.updatePlayerStatus("radio");
  }

  return (
    <div style={{ backgroundColor: "#FFBCD2" }}>
      <Row style={screenContainer} className="text-center">
        <img
          id="radio"
          src="/images/RATURE_LARGE.svg"
          style={{ margin: "2.75em auto 1.25rem", width: "100%" }}
          alt="Nique la Radio - rature"
        ></img>
        <img
          src="/images/PLAY_BUTTON.svg"
          id="radioPlayButton"
          onClick={() => playRadio()}
          alt="Nique la Radio - Play Button"
        ></img>
        <div className="text-center" style={{ width: "100%" }}>
          <h1 style={titleStyle}>LA RADIO 100% GLOBAL DANCEFLOOR</h1>
          {/* <h3 style={subTitleStyle}>
            AFROTRAP, BAILE FUNK, REGGAETON, PERREO, GQOM, AMAPIANO, AFROBEATS,
            RAP, GENGETONE, BREAKBEAT, HOUSE, TECHNO...{" "}
            <span style={{ fontWeight: "bolder" }}>
              CLIQUE, VOYAGE ET DANSE !
            </span>
          </h3> */}
        </div>
      </Row>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    updateSource: function (source) {
      dispatch({ type: "updateSource", source: source });
    },
    updatePlayerStatus: function (status) {
      dispatch({ type: "updatePlayerStatus", status: status });
    },
  };
}

export default connect(null, mapDispatchToProps)(RadioScreen);

const screenContainer = {
  backgroundColor: "#FFBCD2",
  paddingTop: "80px",
  maxWidth: 1000,
  margin: "auto",
};

const titleStyle = {
  fontSize: "2.25rem",
  fontWeight: 700,
};

const subTitleStyle = {
  fontSize: "1.25rem",
  fontWeight: 500,
};
