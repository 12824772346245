import React, { useState } from "react";
import "../App.css";

import Navbar from "react-bootstrap/Navbar";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SoniqueLinkButton from "./soniqueLinkButton.component";

function Navigation() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      className="customNav"
      expand="lg"
      style={navContainerStyle}
      expanded={expanded}
    >
      <Navbar.Brand onClick={() => scroll.scrollToTop()} style={linkStyle}>
        <Link to="/">
          <img src="/logos/NIQUE_LOGO.svg" alt="Nique la Radio - Le Logo" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(expanded ? false : "expanded")}
      />
      <Navbar.Collapse id="basic-navbar-nav" style={navbarCollapse}>
        <SoniqueLinkButton />
        <div style={iconsContainer} className="socialIconsMobilePadding">
          <div className="text-center mr-auto" style={socialIconsContainer}>
            <a
              href="https://www.instagram.com/nique.radio/"
              target="_blank"
              rel="noopener noreferrer"
              style={socialIconsStyle}
            >
              <img
                src="/icons/INSTAGRAM_ICON.svg"
                alt="suivez Nique la Radio sur Instagram"
              ></img>
            </a>
            <a
              href="https://www.tiktok.com/@nique.radio"
              target="_blank"
              rel="noopener noreferrer"
              style={socialIconsStyle}
            >
              <img
                src="/icons/TIK_TOK_ICON.svg"
                alt="suivez Nique la Radio sur TikTok"
              ></img>
            </a>
            <a
              href=" https://twitter.com/nique_radio"
              target="_blank"
              rel="noopener noreferrer"
              style={socialIconsStyle}
            >
              <img
                src="/icons/X-SOCIAL_ICON.svg"
                alt="suivez Nique la Radio sur X"
              ></img>
            </a>
            <a
              href="https://www.threads.net/@nique.radio"
              target="_blank"
              rel="noopener noreferrer"
              style={socialIconsStyle}
            >
              <img
                src="/icons/THREADS_ICON.svg"
                alt="suivez Nique la Radio sur Threads"
              ></img>
            </a>
            <a
              href="https://www.facebook.com/nique.radio/"
              target="_blank"
              rel="noopener noreferrer"
              style={socialIconsStyle}
            >
              <img
                src="/icons/FACEBOOK_ICON.svg"
                alt="suivez Nique la Radio sur Facebook"
              ></img>
            </a>
            <a
              href="https://soundcloud.com/niquelaradio"
              target="_blank"
              rel="noopener noreferrer"
              style={socialIconsStyle}
            >
              <img
                src="/icons/SOUNDCLOUD_ICON.svg"
                alt="suivez Nique la Radio sur Soundcloud"
              ></img>
            </a>
          </div>
          <div style={appIconsContainer}>
            <a
              href="https://apps.apple.com/fr/app/nique-la-radio/id1568745640?itsct=apps_box_link&itscg=30200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icons/app-store-badge.png"
                alt="téléchargez l'application Nique la Radio sur Apple App Store"
                style={{ margin: 0, height: 36, padding: "8px 8px 0" }}
              ></img>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.niqueradio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icons/google-play-badge.png"
                alt="téléchargez l'application Nique la Radio sur Google Play Store"
                style={{ margin: 0, height: 36, padding: "8px 8px 0" }}
              ></img>
            </a>
          </div>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;

const navbarCollapse = {
  justifyContent: "flex-end",
  gap: "20px",
};

const navContainerStyle = {
  position: "fixed",
  left: 0,
  backgroundColor: "#FFBCD2",
  width: "100vw",
  zIndex: 2,
  margin: 0,
  padding: "1vw 3vw",
  display: "flex",
  justifyItems: "center",
  alignItems: "center",
};

const iconsContainer = {
  display: "flex",
  flexDirection: "column",
};

const socialIconsContainer = {
  display: "flex",
  justifyContent: "space-evenly",
};

const appIconsContainer = {
  display: "flex",
  margin: "0 -8px",
};

const socialIconsStyle = {
  marginRight: 16,
};

const linkStyle = {
  textDecoration: "none",
  cursor: "pointer",
};
