import React, { useState, useEffect } from "react";
import "../App.css";
import { connect } from "react-redux";
import Clamp from "react-multiline-clamp";

function Player(props) {
  const [onAir, setOnAir] = useState(false);

  let actionButton = onAir ? (
    <img
      src="/images/PLAYER_PAUSE.svg"
      alt="play-pause button"
      style={{ width: 100, height: "auto", padding: 10 }}
      onClick={() => {
        props.audioMedia.current.pause();
        // audioMedia.current.load();
        setOnAir(false);
      }}
    />
  ) : (
    <img
      src="/images/PLAYER_PLAY.svg"
      alt="play-pause button"
      style={{ width: 100, height: "auto", padding: 10 }}
      onClick={() => {
        if (props.playerStatus === "radio") {
          props.audioMedia.current.load();
        }
        props.audioMedia.current.play();
        setOnAir(true);
      }}
    />
  );

  // mettre à jour le player si on joue la radio

  useEffect(() => {
    if (props.playerStatus === "radio") {
      fetch(
        "https://api.radioking.io/widget/radio/nique-la-radio/track/current"
      )
        .then((response) => response.json())
        .then((body) => {
          props.updateTitleSource(body.title);
          props.updateSubTitleSource(body.artist);
          props.updateCoverSource(body.cover);
        })
        .catch((err) => console.log(err));
    }
  });

  useEffect(() => {
    if (props.playerStatus === "radio") {
      const interval = setInterval(() => {
        fetch(
          "https://api.radioking.io/widget/radio/nique-la-radio/track/current"
        )
          .then((response) => response.json())
          .then((body) => {
            props.updateTitleSource(body.title);
            props.updateSubTitleSource(body.artist);
            props.updateCoverSource(body.cover);
          })
          .catch((err) => console.log(err));
      }, 20000);
      return () => clearInterval(interval);
    }
  });

  // afficher les artist, title et cover infos
  let displayDuration = props.duration !== "" ? props.duration + '"' : "";
  let displayMetadatas;
  if (props.coverSource) {
    displayMetadatas = (
      <div id="player" style={playerContainer}>
        {actionButton}
        <div className="audioInfosContainer">
          <div style={audioInfos}>
            <p style={subtitle}>{props.subTitleSource.toLocaleUpperCase()}</p>
            <Clamp lines={2}>
              <p style={title}>{props.titleSource.toLocaleUpperCase()}</p>
            </Clamp>
            <p style={{ margin: "0", color: "#FFBCD2", fontWeight: "700" }}>
              {displayDuration}
            </p>
          </div>
          <img
            src={props.coverSource}
            alt="song cover img"
            style={{ width: 100, height: "auto", padding: 10 }}
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ transform: "none" }}>
      {displayMetadatas}
      <audio
        ref={props.audioMedia}
        controls
        style={{ display: "none" }}
        onPlay={() => {
          setOnAir(true);
        }}
      >
        <source src={props.source}></source>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    source: state.source,
    titleSource: state.titleSource,
    subTitleSource: state.subTitleSource,
    duration: state.duration,
    coverSource: state.coverSource,
    playerStatus: state.playerStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateTitleSource: function (titleSource) {
      dispatch({ type: "updateTitleSource", titleSource: titleSource });
    },
    updateSubTitleSource: function (subTitleSource) {
      dispatch({
        type: "updateSubTitleSource",
        subTitleSource: subTitleSource,
      });
    },
    updateCoverSource: function (coverSource) {
      dispatch({ type: "updateCoverSource", coverSource: coverSource });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Player);

const playerContainer = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: "100px",
  alignItems: "center",
  backgroundColor: "#000",
  position: "fixed",
  bottom: 0,
};

const audioInfos = {
  textAlign: "end",
};

const title = {
  color: "#FFBCD2",
  fontWeight: 300,
  // fontSize: "1.25rem",
};

const subtitle = {
  color: "#FFBCD2",
  fontWeight: 700,
  // fontSize: "1.25rem",
};
