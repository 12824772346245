import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Footer from "./footer.component";

function MentionsLegalesScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
      <div style={{ backgroundColor: "#FFBCD2" }}>
        <Row style={screenContainer}>
          <div>
            <img
              src="/images/RATURE_LARGE.svg"
              style={{ margin: "2em auto", width: "100%" }}
              alt="Nique la Radio - rature"
            ></img>
            <h1 style={titleStyle}>Mentions Légales</h1>
            <p>
              <span style={{ fontWeight: "bolder" }}>Editeur</span>
              <br />
              Le site Nique.radio (ci-après « le Site ») est édité par l'EURL
              Sonique – Le studio, dont le siège social est situé à la Gaité
              Lyrique - Service résidences, 3 bis rue Papin, 75003 Paris.
            </p>
            <p>contact@sonique.studio</p>
            <p>
              <span style={{ fontWeight: "bolder" }}>
                Directeur de la publication
              </span>
              <br />
              Christophe PAYET
            </p>
            <p>
              <span style={{ fontWeight: "bolder" }}>Conception du site</span>
              <br />
              <a
                href="mailto:massinissa.naitmouloud@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "underline",
                }}
              >
                Massinissa NAIT MOULOUD
              </a>
            </p>
            <p>
              <span style={{ fontWeight: "bolder" }}>D.A.</span>
              <br />
              <a
                href="https://simonsek.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                Simon SEK
              </a>
            </p>
            <p>
              <span style={{ fontWeight: "bolder" }}>Hébergeur</span>
              <br />
              Société OVH
              <br />
              Siège social : 2 rue Kellermann - 59100 Roubaix, France
              <br />
              Tél. +33 (0)8 99 70 17 61
              <br />
              Site web :{" "}
              <a
                href="https://www.ovh.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                www.ovh.com
              </a>
            </p>
            <p>
              <span style={{ fontWeight: "bolder" }}>
                Loi informatique et libertés
              </span>
              <br />
              <span
                style={{
                  textDecoration: "underline",
                  textDecorationStyle: "double",
                }}
              >
                Données à caractère personnel
              </span>
              <br />
              Conformément à la loi « Informatique et Libertés » du 6 janvier
              1978, modifiée par la loi du 6 août 2004, vous êtes informé(e) que
              la société Nique – La radio procède à des traitements automatisés
              de vos données personnelles recueillies dans le cadre des
              formulaires en ligne remplis par vos soins. (Déclaration CNIL
              n°1815721) Les données personnelles collectées dans le cadre des
              inscriptions au Site sont susceptibles d'être utilisées par Nique
              – La radio exclusivement aux fins suivantes :- envoi de
              newsletters, de propositions commerciales et/ou promotionnelles,
              émanant de Nique – La radio et concernant exclusivement les
              produits et services de Nique – La radio.- envoi de propositions
              commerciales et/ou promotionnelles, émanant de sociétés tierces en
              relation avec Nique – La radio et ne concernant pas exclusivement
              les produits et services de Nique – La radio. Cet envoi sera
              effectué exclusivement par Nique – La radio. Aucune vente de base
              de données à des sociétés tierces n'est faite par Nique – La
              radio. Cet envoi de propositions commerciales et/ou
              promotionnelles sera effectué conformément à l'article L34-5 du
              Code des Postes et Télécommunications. (Déclaration CNIL
              n°1801974v0) Nique – La radio ne conserve vos données que pour une
              durée nécessaire et proportionnelle à la finalité pour laquelle
              elles ont été collectées. Pour les données utilisées à des fins de
              prospection cette durée est de trois ans à compter de la fin de la
              relation commerciale. Conformément aux dispositions de la loi du 6
              janvier 1978 relative aux fichiers, à l'informatique et aux
              libertés, vous disposez d'un droit d'accès, de modification, de
              rectification et de suppression des données qui vous concernent.
              Vous pouvez exercer vos droits en vous adressant à Nique – La
              radio par courrier électronique à l’adresse courriel suivante
              contact@sonique.studio. Dans un souci de confidentialité et de
              protection des données personnelles, toute demande tendant à
              l’exercice de ces droits devra être accompagnée d’une copie d’un
              titre d’identité signé.
            </p>
            <p>
              <span
                style={{
                  textDecoration: "underline",
                  textDecorationStyle: "double",
                }}
              >
                Utilisation des Cookies
              </span>
              <br />
              Un ou plusieurs "cookies" seront placés sur le disque dur de
              l'ordinateur des internautes afin qu'ils soient directement
              identifiés lorsqu'ils se connectent au site, de faciliter la
              gestion des comptes. Les internautes sont informés de la
              possibilité de s'opposer à l'installation de "cookies" sur son
              ordinateur. En aucun cas une corrélation n'est faite entre ces
              cookies et des informations nominatives que pourrait détenir Nique
              – La radio.
            </p>
            <p>
              <span
                style={{
                  textDecoration: "underline",
                  textDecorationStyle: "double",
                }}
              >
                Qu'est-ce qu'un cookie ?
              </span>
              <br />
              Les cookies sont des petits programmes informatiques que le Site
              Internet place sur le disque dur de votre ordinateur, de votre
              tablette, ou de votre téléphone, qui ont pour but de collecter des
              informations relatives à votre navigation (pages consultées, date
              et heure de consultation, etc.) et d’adresser des contenus et
              services adaptés à vos centres d’intérêts. Les cookies ne
              permettent pas de vous identifier en tant qu'utilisateur physique
              mais identifient votre ordinateur. Il existe deux types de cookies
              : les cookies de sessions et les cookies persistants. Les cookies
              de session sont des bits temporaires d'information qui sont
              effacés quand vous quittez votre navigateur. Les cookies
              persistants sont des bits d'informations plus permanents qui sont
              stockés et restent sur votre ordinateur jusqu'à leur suppression.
              Les cookies persistants se suppriment automatiquement après un
              certain temps mais sont recréés à chaque fois que vous visitez le
              Site Internet. Le Site Internet Nique – La radio (ci-après « le
              Site Internet ») utilise des cookies de session et des cookies
              persistants.
            </p>
          </div>
        </Row>
      </div>
      <Footer />
    </Container>
  );
}

export default MentionsLegalesScreen;

const screenContainer = {
  backgroundColor: "#FFBCD2",
  paddingTop: "120px",
  // padding: "120px 50px 50px",
  maxWidth: 1000,
  margin: "auto",
};

const titleStyle = {
  fontSize: "2.25rem",
  fontWeight: 900,
  textTransform: "uppercase",
};
